/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import styles from './Nav.module.scss';

import Image from '@/components/Image/Image';
import SocialIcons from '@/components/SocialIcons/SocialIcons';

import routes from '@/data/routes';

function Nav() {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <>
      <header className="shadow-sm sticky bg-white">
        <div className="horizontal-spacing">
          <div className="flex items-center justify-between space-x-4 lg:space-x-10">
            <div className="flex lg:w-0 lg:flex-1 relative">
              <div className={classnames('w-56 md:w-48 lg:w-56 bg-brandorange', styles.background)}></div>
              <Link href={routes.Home.path}>
                <a className="block w-20 h-14 rounded-lg relative">
                  <Image
                    className="absolute w-full h-full image-cover"
                    src="/assets/images/ryna-logo.png"
                    alt="Ryna main logo"
                  />
                </a>
              </Link>
            </div>

            <nav className="opacity-0 w-0 md:opacity-100 md:w-auto space-x-6 text-sm font-bold flex p-4 justify-center items-center">
              <SocialIcons />
              {Object.values(routes).map(({ title, path }, index) => (
                <Link href={path} key={index}>
                  <a className="gray-color">{title}</a>
                </Link>
              ))}
            </nav>

            <div className="lg:hidden z-10">
              <button
                className="p-2 text-gray-600 bg-gray-100 rounded-lg"
                type="button"
                onClick={() => setOpenMenu(true)}
              >
                <span className="sr-only">Open menu</span>
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 6h16M4 12h16M4 18h16" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </header>
      {openMenu && (
        <section className="fixed inset-y-0 right-0 z-50 flex">
          <div className="w-screen max-w-sm">
            <div className="flex flex-col h-full divide-y divide-gray-200 bg-gray-50">
              <div className="space-y-6 overflow-y-scroll">
                <header className="flex items-center justify-between h-16 pl-6 border-b border-gray-200">
                  <span className="text-sm font-medium tracking-widest uppercase"> Menu </span>

                  <button
                    aria-label="Close menu"
                    className="w-16 h-16 border-l border-gray-200"
                    type="button"
                    onClick={() => setOpenMenu(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mx-auto"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </header>

                <div className="px-6">
                  <h2 className="text-sm font-medium tracking-widest text-brandblue uppercase mb-4">Little Ryna</h2>
                  <SocialIcons />
                </div>

                <nav className="flex flex-col text-sm font-medium gray-color border-t border-b border-gray-200 divide-y divide-gray-200">
                  {Object.values(routes).map(({ title, path }, index) => (
                    <Link href={path} key={index}>
                      <a className="flex items-center justify-between px-6 py-3" onClick={() => setOpenMenu(false)}>
                        {title}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                        </svg>
                      </a>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default Nav;
