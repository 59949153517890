import { memo } from 'react';
import classnames from 'classnames';

import FacebookSVG from '@/components/svgs/facebook-official.svg';
import InstagramSVG from '@/components/svgs/instagram-icon.svg';

export type Props = {
  className?: string;
};

const LINKS = [
  { href: 'https://www.facebook.com/Little-Ryna-101949998657869', label: 'Facebook', file: FacebookSVG },
  { href: 'https://www.instagram.com/little-ryna', label: 'Instagram', file: InstagramSVG }
].map((link) => ({
  ...link,
  key: `nav-link-${link.href}-${link.label}`
}));

function SocialLinks({ className }: Props) {
  return (
    <ul className={classnames(className, 'flex grow items-center space-x-4')}>
      {LINKS.map(({ href, label, file }, index) => {
        const Icon = file;

        return (
          <li key={index}>
            <a
              className="w-6 h-6 block relative hover:text-primary transition-transform duration-500 hover:scale-105"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={label}
            >
              <Icon className="absolute w-full h-full" />
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default memo(SocialLinks);
