import { memo } from 'react';
import Link from 'next/link';

import Image from '@/components/Image/Image';
import SocialIcons from '@/components/SocialIcons/SocialIcons';

import routes from '@/data/routes';

function Footer() {
  return (
    <footer className="Footer bg-gray-50 bg-brandorange/[.6]">
      <div className="horizontal-spacing pt-12 py-8">
        <span className="block w-28 h-16 rounded-md relative">
          <Image
            className="absolute w-full h-full image-cover"
            src="/assets/images/ryna-logo.png"
            alt="Footer logo"
          ></Image>
        </span>

        <div className="grid grid-cols-1 gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-4">
          <div>
            <p className="font-medium">Sitemap</p>

            <nav className="flex flex-col mt-4 space-y-2 text-sm gray-color">
              {Object.values(routes).map(({ title, path }, index) => (
                <Link href={path} key={index}>
                  <a className="hover:opacity-75">{title}</a>
                </Link>
              ))}
            </nav>
          </div>
          <SocialIcons />
        </div>

        <div className="pt-8 mt-8 border-t border-gray-700 sm:items-center sm:justify-between sm:flex">
          <p className="text-xs gray-color mb-4">&copy; 2022-{new Date().getFullYear()} Little Ryna</p>

          <strong className="inline-flex items-center p-2 space-x-2 text-sm font-medium border border-gray-600 rounded mb-4">
            <span>
              Made w/&nbsp;
              <a className="lowercase underline" href="https://hyperui.dev/">
                hyperui
              </a>
            </span>
          </strong>
        </div>
      </div>
    </footer>
  );
}

export default memo(Footer);
