import { ForwardedRef, forwardRef, memo } from 'react';
import classnames from 'classnames';

import { Breakpoints } from '@/utils/layout';

type SrcSetSizes = {
  [breakpoint in keyof Breakpoints]: string;
};

export type Props = {
  className?: string;
  src: string;
  alt: string;
  loadingType?: 'lazy' | 'eager';
  sizes?: Partial<SrcSetSizes>;
};

const Image = ({ className, src, alt, loadingType = 'lazy' }: Props, ref: ForwardedRef<HTMLImageElement>) => {
  return (
    <img
      className={classnames('block w-full', className)}
      ref={ref}
      src={src}
      alt={alt}
      decoding="async"
      loading={loadingType}
    />
  );
};

export default memo(forwardRef(Image));
