export type Route = {
  readonly path: string;
  readonly title: string;
};

export interface Routes {
  readonly Home: Route;
  readonly Contact: Route;
  readonly Policies: Route;
}

const routes: Routes = {
  Home: {
    path: '/',
    title: 'Home'
  },
  Contact: {
    path: '/contact/',
    title: 'Contact'
  },
  Policies: {
    path: '/policies/',
    title: 'Policies'
  }
};

export default routes;
