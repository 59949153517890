import { memo, PropsWithChildren } from 'react';

import Footer from '@/components/Footer/Footer';
import Nav from '@/components/Nav/Nav';

import { GtmScript } from '@/utils/analytics';

export type Props = PropsWithChildren<{}>;

function Layout({ children }: Props) {
  return (
    <>
      <GtmScript consent={true} />

      <Nav />
      {children}
      <Footer />
    </>
  );
}

export default memo(Layout);
